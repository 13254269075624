import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Why dataviz does better for your career than TODOapps",
  "description": "Stop learning to code TODOapps! Why not learn modern React by building apps you can show off?",
  "date": "2019-03-06T08:00:00.000Z",
  "published": "2019-03-06T08:00:00.000Z",
  "image": "./img/6DCi7HQ.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Ah the infamous TODOapp. Remember those?`}</p>
    <p>{`Feels like TODOapps were everywhere a few years ago.`}</p>
    <p><em parentName="p">{`"`}{`Look at me! I built a TODOapp with React`}{`"`}</em></p>
    <p><em parentName="p">{`"`}{`Mine`}{`'`}{`s in Vue!`}{`"`}</em></p>
    <p><em parentName="p">{`"`}{`Mine`}{`'`}{`s Angular`}{`"`}</em></p>
    <p><em parentName="p">{`"`}{`Hey over here, this TODOapp uses styled components and GraphQL`}{`"`}</em></p>
    <p><img parentName="p" {...{
        "src": "/2d1ac56d47bc5a62d331a08700857288/media-k81K0IbVvzLINr19Bx-giphy.gif",
        "alt": null
      }}></img></p>
    <p>{`Who cares. How many TODOapps do you need, sheesh`}</p>
    <p>{`The TODOapp and the counter component star in every tutorial for a new framework or language. Quick to understand, a little complex but not too much, absolutely boring to build and look at.`}</p>
    <p>{`Don`}{`'`}{`t you want something better to do?`}</p>
    <p>{`Why not learn modern React by building apps you can show off?`}</p>
    <p>{`Something you can show your friends and they`}{`'`}{`ll say `}<em parentName="p">{`"`}{`Wow that`}{`'`}{`s cool`}{`"`}</em>{`, show your spouse and they`}{`'`}{`ll go `}<em parentName="p">{`"`}{`That`}{`'`}{`s nice honey`}{`"`}</em>{`, or your mum and she`}{`'`}{`ll say `}<em parentName="p">{`"`}{`Holy shitballs that`}{`'`}{`s the most amazingest thing I have ever seen did you eat your veggies!?`}{`"`}</em></p>
    <p>{`Veggies are `}{`\\`}{`important.`}</p>
    <p>{`Don`}{`'`}{`t you want to build something you`}{`'`}{`re gonna have fun building? Something that makes you proud and looks good on your portfolio.`}</p>
    <p>{`Yeah me too :)`}</p>
    <p>{`Here`}{`'`}{`s how I learned React 👇`}</p>
    <p><a parentName="p" {...{
        "href": "https://swizec.github.io/h1b-software-salaries/"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "890px",
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95067264573991%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'228\\'%20viewBox=\\'0%200%20400%20228\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M74%207h-1c-2-2-4-1-4%202%200%204%202%205%204%202h14c1%202%204%200%204-2s-2-3-3-2h-7c-1-2-4-1-4%201-1%201-1%201-1-1s-2-3-2%200m-41%2094l1%202%201%202%201%202%203%201%202-1c0-1%201-2%203-2l2%202c0%202%201%203%202%203l1-1%202-2%201-1-3-2c-1-2-2-3-5-3l-8-2c-3%200-3%200-3%202m172%201l-1%205v51l1%201-1%2015c1%206%203%207%203%200l-1-5c-1-1%2012-1%2028-1h29v-11h85v-11H244v-11h-20l-15-1h5v-10h-4c-2%200-3%200-2-1%201%200%202-11%200-11v-5c0-5-1-7-3-5m-71%20108l-1%206v6h21v-13h-10l-10%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a8c28cd613243e5cffe26e16ae9f8fed/ca0a1/6DCi7HQ.webp 223w", "/static/a8c28cd613243e5cffe26e16ae9f8fed/75680/6DCi7HQ.webp 445w", "/static/a8c28cd613243e5cffe26e16ae9f8fed/8d1ba/6DCi7HQ.webp 890w", "/static/a8c28cd613243e5cffe26e16ae9f8fed/3838e/6DCi7HQ.webp 1335w", "/static/a8c28cd613243e5cffe26e16ae9f8fed/e11e5/6DCi7HQ.webp 1780w", "/static/a8c28cd613243e5cffe26e16ae9f8fed/5dc5e/6DCi7HQ.webp 2340w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a8c28cd613243e5cffe26e16ae9f8fed/e92b6/6DCi7HQ.png 223w", "/static/a8c28cd613243e5cffe26e16ae9f8fed/e66bf/6DCi7HQ.png 445w", "/static/a8c28cd613243e5cffe26e16ae9f8fed/4ef49/6DCi7HQ.png 890w", "/static/a8c28cd613243e5cffe26e16ae9f8fed/4e814/6DCi7HQ.png 1335w", "/static/a8c28cd613243e5cffe26e16ae9f8fed/701e9/6DCi7HQ.png 1780w", "/static/a8c28cd613243e5cffe26e16ae9f8fed/92bee/6DCi7HQ.png 2340w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a8c28cd613243e5cffe26e16ae9f8fed/4ef49/6DCi7HQ.png",
              "alt": "6DCi7HQ",
              "title": "6DCi7HQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></a></p>
    <p>{`Straight to the HackerNews front page it went. 21,259 people saw my very first React app on its first day. 71,894 in total. That`}{`'`}{`s a new screenshot from localhost, I need to update production :)`}</p>
    <p>{`You build this in `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com/"
      }}>{`React for Data Visualization`}</a>{`. Yours is gonna use modern React patterns and a special chapter shows you how to refactor with React Hooks. Those came out just last month. 🤙`}</p>
    <p>{`My next app was about learning Flux.`}</p>
    <p>{`This was before Redux. First time people started talking about the `}{`"`}{`unidirectional dataflow`}{`"`}{` and how it revolutionizes the way we think about web apps.`}</p>
    <p>{`So I built a space invaders game.`}</p>
    <p><img parentName="p" {...{
        "src": "/228ae441aac6cd7f4f496586b97f6cf1/OMthAsb.gif",
        "alt": null
      }}></img></p>
    <p>{`That`}{`'`}{`s when I realized building a data visualization is just like a video game. Everything you build with React is like a video game.`}</p>
    <p>{`You have your game engine, that`}{`'`}{`s React, it handles renders and rerenders, figures out what changes from one paint to another, etc. You never have to worry about any of that.Build your components, take care of your business logic, and React figures out how to translate your imagination to your DOM.`}</p>
    <p>{`👌`}</p>
    <p><a parentName="p" {...{
        "href": "http://swizec.github.io/space-invaders/"
      }}>{`React Space Invaders`}</a>{` was good enough for a talk at a huge conference in San Francisco. My room was packed full. No pressure 😅`}</p>
    <p>{`This one doesn`}{`'`}{`t feature in `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com/"
      }}>{`React for Data Visualization`}</a>{` but the lessons I learned are all there: A whole chapter on the game loop approach to fine-tuned animation.`}</p>
    <p>{`Then someone asked me `}<em parentName="p">{`"`}{`What if we have tens of thousands of datapoints to display?`}{`"`}</em></p>
    <p>{`And I decided to find out. Why not learn Redux while we`}{`'`}{`re at it. The result was a semi-famous React particle generator that animates 20,000 data points even on your phone. 😱`}</p>
    <p><a parentName="p" {...{
        "href": "http://swizec.github.io/react-particles-experiment/"
      }}><img parentName="a" {...{
          "src": "/e5df0eba08eeb796cc0de624fdffeaa1/static-examp6-e5df0eba08eeb796cc0de624fdffeaa1.gif",
          "alt": null
        }}></img></a></p>
    <p>{`Fun to build and that series of blog posts got thousands of readers. Even got me a spot on a web animation panel at ForwardJS that year. 🤘`}</p>
    <p><img parentName="p" {...{
        "src": "https://scontent-sjc3-1.cdninstagram.com/vp/34ac302718250da5f52ead8134d5d32c/5D07FA94/t51.2885-15/sh0.08/e35/s640x640/13722008_509397825922904_1555033609_n.jpg?_nc_ht=scontent-sjc3-1.cdninstagram.com",
        "alt": null
      }}></img></p>
    <p>{`I bombed the panel but I was there. That looked `}<em parentName="p">{`great`}</em>{` on my visa application.`}</p>
    <p>{`Here`}{`'`}{`s another popular one: The dancing pythagorean fractal tree. Started as an experiment in recursion with React components, turned into a stress test, then a comparison of web frameworks.`}</p>
    <p><img parentName="p" {...{
        "src": "/7f75dd26091dd6fc9c2486b9dd8bdb99/static-examp8-7f75dd26091dd6fc9c2486b9dd8bdb99.gif",
        "alt": null
      }}></img></p>
    <p>{`@`}{`_`}{`developit, creator of Preact, and @youyuxi, creator of Vue, liked it so much they built their own versions to test Preact and Vue. The experiment features in `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`React for Data Visualization`}</a>{` so you can compare and see which framework fits your style.`}</p>
    <p>{`D3 doesn`}{`'`}{`t discriminate. Same approach works with all modern component-based frameworks. I just happen to like React :)`}</p>
    <p>{`Here`}{`'`}{`s another fun one`}</p>
    <p>{`Did you know more Americans move to Mexico every decade than the other way around? UN data says so.`}</p>
    <p><a parentName="p" {...{
        "href": "https://swizec.github.io/migrations-map/"
      }}><img parentName="a" {...{
          "src": "/f32208e9970b62a4cdff1d7317f6bbfe/tPt23gU.gif",
          "alt": null
        }}></img></a></p>
    <p>{`I don`}{`'`}{`t know what I tried to learn there but it looks cool. Colors for intensity of immigration, arcs with flying circles show density, searchable dropdown helps you explore the map.`}</p>
    <p>{`Makes people go wow at the dinner table 😛`}</p>
    <p>{`Ready to make `}<em parentName="p">{`your`}</em>{` dinner table go wow? Consider joining React for Data Visualization 👇`}</p>
    <p><a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`reactfordataviz.com`}</a></p>
    <p>{`Prices go up this Friday.`}</p>
    <p>{`Cheers, ~Swizec`}</p>
    <p>{`PS: I`}{`'`}{`m adding a bunch of bonus material this weekend. Plus a gift for students who buy this week`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      